.preloader {
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #101520;
    animation: preloader 4s linear forwards;
    /* opacity: 1; */

}

.preloader__content {
    width: 250px;
    height: 250px;
    /* background-color: #010001; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.preloader__content img,
.preloader__content video {
    width: 100%;
    /* display: none; */
}

@keyframes preloader {
    0% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    90% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
        pointer-events: none;
    }

}

.lds-facebook {
    display: flex;
    /* position: absolute; */
    justify-content: center;
    justify-items: center;
    align-content: space-between;
    /* flex-wrap: wrap; */
    width: 180px;
    height: 247px;    
    background: url('../../../images/noimage.png') top no-repeat;
  }
  .lds-facebook div {
    width: 24px;
    background: #fff;
    -webkit-animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    margin: 12px;
    justify-content: space-between;
    margin-top: auto;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  